@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Radomir Tinkov - Gilroy-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Radomir Tinkov - Gilroy-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Radomir Tinkov - Gilroy-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Radomir Tinkov - Gilroy-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Radomir Tinkov - Gilroy-ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Radomir Tinkov - Gilroy-ExtraBoldItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Radomir Tinkov - Gilroy-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Radomir Tinkov - Gilroy-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Radomir Tinkov - Gilroy-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Radomir Tinkov - Gilroy-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Radomir Tinkov - Gilroy-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Radomir Tinkov - Gilroy-SemiBoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Radomir Tinkov - Gilroy-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Radomir Tinkov - Gilroy-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Radomir Tinkov - Gilroy-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Radomir Tinkov - Gilroy-RegularItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

body {
  font-family: 'Gilroy';
  background: #f8faff;
  /* background: #F5F7FA; */
  color: #374151;
}

.App {
  /* width:100vw; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .subtask::after {
  content: "";
  position: absolute;
  height: 3.5rem;
  width: calc(1.75rem - 3px);
  top: -1.2rem;
  left: 0rem;
  border-bottom: 1px solid #E2E8F0;
  border-left: 1px solid #E2E8F0;
} */

th.font-bold {
  font-weight: 700;
}

::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.subtask::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 1px;
  top: 0rem;
  left: 0rem;
  z-index: 0;
  /* border-left: 1px solid #1C64F2; */
  border-left: 1px solid #cbd5e1;
}

.align-subtask::before {
  content: '';
  position: absolute;
  height: 22px;
  width: 17px;
  top: 0rem;
  left: 0rem;
  /* border-left: 1px solid #1C64F2; */
  border-left: 1px solid #cbd5e1;
  border-bottom: 1px solid #cbd5e1;
  border-bottom-left-radius: 0.25rem;
}

.add-subtask::before {
  content: '';
  position: absolute;
  height: 22px;
  width: 17px;
  top: -14px;
  left: 0rem;
  z-index: 1;
  /* border-left: 1px solid #1C64F2; */
  border-style: dashed !important;
  border-left: 1px solid #1c64f2;
  border-bottom: 1px solid #1c64f2;
  border-bottom-left-radius: 0.25rem;
}

.craft-order::before {
  content: '';
  position: absolute;
  height: 50px;
  width: 48px;
  top: -24px;
  left: -30px;
  z-index: 1;
  /* border-left: 1px solid #1C64F2; */
  border-style: dashed !important;
  border-left: 1px solid #1c64f2;
  border-bottom: 1px solid #1c64f2;
  border-bottom-left-radius: 0.25rem;
}

.comment {
  position: relative;
}
.comment::after {
  content: '';
  width: 16px;
  height: 16px;
  background-color: #f8fafc;
  border: 1px #cbd5e1 solid;
  position: absolute;
  left: -8px;
  top: 10.5px;
  transform: rotate(45deg);
}
#ribbon::after {
  content: '';
  background: #162551;
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  left: 0;
  bottom: -0.5rem;
  border-bottom-left-radius: 0.25rem;
}

.shadow-ambient {
  box-shadow: 0 1px 3px hsl(225deg 59% 16% / 15%), 0 1px 2px hsl(225deg 59% 16% / 12%);
}

.slc-checked {
  position: absolute;
}
.slc-checked:checked::after {
  content: '';
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 100%;
  border: 2px white solid;
  position: absolute;
  top: 0;
  left: 0;
}

.slc-checked:checked + label {
  background-color: #dde8fd;
  border-color: #1c64f2;
}

.w-md-editor {
  border-radius: 0.25rem 0.25rem 0 0 !important;
  box-shadow: none !important;
}

.w-md-editor-bar {
  right: 0.5rem !important;
}

.shadow-blue-lg {
  box-shadow: rgba(7, 65, 210, 0.08) 0px 9px 30px;
}

.shadow-blue {
  box-shadow: rgb(7 65 210 / 8%) 0px 1px 17px;
}

.shadow-blue-t {
  box-shadow: rgb(7 65 210 / 8%) 0px 1px 17px;
  clip-path: inset(-150px -150px 0px -150px);
}

.shadow-blue-b {
  box-shadow: rgb(7 65 210 / 8%) 0px 1px 17px;
  clip-path: inset(0px -150px -150px -150px);
}

.hover-shadow-blue:hover {
  box-shadow: rgb(7 65 210 / 8%) 0px 1px 17px;
}

.hover-shadow {
  box-shadow: rgba(17, 29, 65, 0.16) 0px 5px 40px;
}

.hover-shadow-flyout {
  box-shadow: rgba(17, 29, 65, 0.16) 0px 5px 40px;
  clip-path: inset(0px -150px -150px -150px);
}

.bt-full > button {
  width: 100%;
}
.no-head > thead {
  display: none;
}

.sm-head > thead {
  font-size: 0.75rem;
  line-height: 1rem;
  border-color: rgb(226 232 240 / 1);
}
.sm-head > thead > tr {
  background-color: rgb(248 250 255) !important;
  margin-bottom: 0 !important;
}
.sm-head > thead > tr > th {
  padding-top: 0.75rem;
  color: #233876;
  padding-bottom: 0.75rem;
}

.sm-head.force-color > thead > tr > th {
  color: #111d41;
}

th.th-white {
  background: #fff !important;
  border-left-color: #fff !important;
  border-right-color: #fff !important;
}

.after-caret::after {
  transform: rotate(45deg);
  content: '';
  width: 10px;
  height: 10px;
  background-color: #f8fafc;
  border-top: 1px solid #cbd5e1;
  border-left: 1px solid #cbd5e1;
  position: absolute;
  top: -6px;
  left: 18px;
}

.hidden-check:checked {
  display: none;
}

.text-right::-webkit-textfield-decoration-container {
  flex-direction: row-reverse;
}

.hidden-check:checked + label {
  border-style: solid;
  /* background-color:#E8FAF4; */
  background-color: #dde8fd;
  /* border-color:#1DCD92; */
  border-color: #1c64f2 !important;
}

.hidden-check:checked + label + svg {
  display: block !important;
}

.hidden-check:checked + label > div > div > span {
  color: #1c64f2 !important;
}

.badge-after::after {
  content: '';
  height: 15px;
  width: 15px;
  background: white;
  border-left: 1px solid #e4e6ea;
  border-bottom: 1px solid #e4e6ea;
  position: absolute;
  left: -7.5px;
  transform: rotate(45deg);
  z-index: 20;
}

.construction-bar::before {
  content: '';
  height: 0.5rem;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 69;
  background: repeating-linear-gradient(45deg, #facc15, #facc15 10px, #111d41 10px, #111d41 20px);
}

input.strip-style::-webkit-outer-spin-button,
input.strip-style::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.strip-style[type='number'] {
  -moz-appearance: textfield;
}

thead > tr.no-hover {
  border: 0 !important;
}

.hover-shadow > .hover-shadow {
  box-shadow: none !important;
}

.toolip::after {
  pointer-events: none;
  background-color: rgb(17 29 65);
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  position: absolute;
  white-space: nowrap;
  right: 100%;
  margin-left: 0.25rem;
  z-index: 40;
  opacity: 0;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.tooltip:hover::after {
  opacity: 1;
}

.invoice-thead > thead {
  opacity: 0;
  border: 0 none;
  height: 0;
}
.invoice-thead > thead > * > * {
  margin: 0;
  padding: 0;
  border: 0 none;
  height: 0px;
}

.signature {
  font-family: 'Dancing Script', cursive;
}

input[type='number'].no-spinner::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

